
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  setup() {
    const list:Array<any> = reactive([])
    list.push("我是最外成的微任务？")
    setTimeout(()=> {
      list.push("我是浏览器的宏任务1")
    })
    setTimeout(() => {
      list.push("我是浏览器的宏任务2")
      new Promise((resolve:any) => {
        resolve()
        list.push("我是宏任务里面的微任务1")
      }).then(()=> {
        list.push("我是宏任务里面的微任务1的回调1")
      })
      list.push("我是宏任务里面微任务后面的")
    })
    const ll = new Promise((resolve:any) => {
      list.push("我是promise里面的微任务?")
      resolve()
    })
    list.push("hello")
    ll.then(() => {
      list.push("我是微服务的回调1")
    }).then(() => {
      list.push("我是微服务的回调2")
    })
    return {
      list
    }
  }
})
